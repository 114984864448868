import { courses as coursesTypes, blog as blogTypes } from '@codesass/types';

import Header from './Header';
import Courses from './Courses';
import WhyLearnWithUs from './WhyLearnWithUs';
import Articles from './Articles';

type HomeProps = {
  courses: coursesTypes.CourseListItem[];
  articles: blogTypes.ArticleListItem[];
};

const Home = ({ courses, articles }: HomeProps) => {
  return (
    <>
      <Header></Header>
      <Courses courses={courses}></Courses>
      <WhyLearnWithUs></WhyLearnWithUs>
      <Articles articles={articles}></Articles>
    </>
  );
};

export default Home;
