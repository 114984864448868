import { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';

import { courses as coursesTypes, blog as blogTypes } from '@codesass/types';

import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

import Home from 'modules/home/components/Home';
import SEO from 'modules/ui/components/SEO';

type IndexPageData = {
  allCoursesYaml: {
    nodes: (Omit<coursesTypes.CourseListItem, 'slug'> & {
      fields: { slug: coursesTypes.Course['slug'] };
    })[];
  };
  allMdx: {
    nodes: {
      frontmatter: Pick<
        blogTypes.Article,
        'title' | 'excerpt' | 'tags' | 'updatedAt'
      > & {
        image: FileNode;
      };
      slug: string;
    }[];
  };
};

const IndexPage = ({
  data: {
    allCoursesYaml: { nodes: courseNodes },
    allMdx: { nodes: articleNodes },
  },
}: PageProps<IndexPageData>): ReactNode => {
  const courses = courseNodes.map(({ fields, ...rest }) => ({
    ...rest,
    ...fields,
  }));
  const articles = articleNodes.map(({ frontmatter, slug }) => ({
    ...frontmatter,
    slug,
    image: frontmatter.image,
  }));

  return <Home courses={courses} articles={articles}></Home>;
};

export const query = graphql`
  query IndexQuery {
    allCoursesYaml(sort: { fields: createdAt, order: DESC }, limit: 4) {
      nodes {
        title
        price
        promotion
        image {
          childImageSharp {
            gatsbyImageData(quality: 50, width: 567, layout: CONSTRAINED)
          }
        }
        fields {
          slug
        }
      }
    }
    allMdx(
      filter: { frontmatter: { contentType: { eq: "article" } } }
      sort: { fields: frontmatter___createdAt, order: DESC }
      limit: 4
    ) {
      nodes {
        frontmatter {
          excerpt
          updatedAt(locale: "th", formatString: "DD MMM YYYY")
          title
          tags
          image {
            childImageSharp {
              gatsbyImageData(quality: 50, width: 567, layout: CONSTRAINED)
            }
          }
        }
        slug
      }
    }
  }
`;

export const Head = () => {
  return (
    <>
      <SEO
        title="โค้ดศาสตร์ --- ศาสตร์การเขียนโค้ดเริ่มต้นที่นี่"
        path="/courses"
        type="article"
        desc="โค้ดศาสตร์ --- ศาสตร์การเขียนโค้ดเริ่มต้นที่นี่ เราจึงคัดสรรคอร์สที่เหมาะสมกับผู้เรียนทุกระดับ เพื่ออัพสกิลสู่การเป็นโปรแกรมเมอร์สกิลเทพ"
      ></SEO>
      <meta
        name="facebook-domain-verification"
        content="azzgg91pshbqm8c3n05fmh18829qd8"
      />
    </>
  );
};

export default IndexPage;
