import { ComponentProps } from 'react';

import CardList from 'modules/courses/components/desc/CardList';

const cardListProps: ComponentProps<typeof CardList> = {
  bgColor: '#fff',
  header: {
    type: 'Text',
    titleColor: '#000',
    textColor: 'grey.700',
    title: 'ทำไมต้องเรียนกับโค้ดศาสตร์?',
    text: 'เนื้อหาที่ครอบคลุม สั้น กระชับ จากผู้สอนมืออาชีพ พร้อมแบบฝึกหัดที่จะช่วยยกระดับการเรียนรู้ของคุณ\nเมื่อผู้เรียนผ่านการทดสอบท้ายคอร์ส รับทันทีใบ Certification เพื่อนำไปอัพเกรดโปรไฟล์\n',
  },
  align: 'center',
  spacing: 1.5,
  sizes: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  },
  itemConfig: {
    titleColor: 'grey.900',
    descColor: 'grey.800',
    bgColor: 'transparent',
    elevation: 0,
    imageHeight: 200,
  },
  items: [
    {
      title: 'เนื้อหาครอบคลุม',
      desc: 'ไม่ใช่เพียงพื้นฐาน แต่เราสอนเนื้อหาหลากหลายให้ครอบคลุมในทุกมิติของการพัฒนาซอฟต์แวร์',
      image: {
        url: 'web:cover.png',
        alt: 'เนื้อหาครอบคลุม',
        stretch: false,
      },
    },
    {
      title: 'มีแบบฝึกหัดให้ทำ',
      desc: 'เราเชื่อว่าการเรียนรู้ที่ดีต้องหมั่นฝึกฝน เราจึงเตรียมแบบทดสอบไว้ให้ผู้เรียนทดลองทำด้วยตนเอง\n',
      image: {
        url: 'web:exam.png',
        alt: 'มีแบบฝึกหัดให้ทำ',
        stretch: false,
      },
    },
    {
      title: 'ใบรับรองหลักสูตร',
      desc: 'เมื่อผู้เรียนผ่านการทดสอบท้ายคอร์ส รับใบ Certificate เพื่ออัพโปรไฟล์ได้ทันที\n',
      image: {
        url: 'web:certification.png',
        alt: 'ใบรับรองหลักสูตร',
        stretch: false,
      },
    },
  ],
};

const WhyLearnWithUs = () => {
  return <CardList {...cardListProps}></CardList>;
};

export default WhyLearnWithUs;
