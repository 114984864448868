import { Box, Typography } from '@mui/material';

import { blog as types } from '@codesass/types';

import ArticleList from 'modules/blog/components/ArticleList';

import ButtonLink from 'modules/ui/components/ButtonLink';

type ArticlesProps = {
  articles: types.ArticleListItem[];
};

const Articles = ({ articles }: ArticlesProps) => {
  return (
    <Box m={2} display="flex" justifyContent="center">
      <Box maxWidth={1_100} width="100%">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Typography variant="h4">บทความล่าสุด</Typography>
          <ButtonLink color="primary" variant="contained" to="/courses">
            ดูทั้งหมด
          </ButtonLink>
        </Box>
        <ArticleList articles={articles}></ArticleList>
      </Box>
    </Box>
  );
};

export default Articles;
