import { Container, Grid } from '@mui/material';

import { blog as types } from '@codesass/types';

import ArticleItem from './ArticleItem';

type ArticleListProperties = {
  articles: types.ArticleListItem[];
};

const ArticleList = ({ articles }: ArticleListProperties) => {
  return (
    <Container>
      <Grid container spacing={2} role="list">
        {articles.map(article => (
          <ArticleItem key={article.slug} {...article}></ArticleItem>
        ))}
      </Grid>
    </Container>
  );
};

export default ArticleList;
