import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';

import { blog as types } from '@codesass/types';

import Link from 'modules/ui/components/Link';

const ArticleItem = ({
  slug,
  title,
  excerpt,
  image,
  updatedAt,
}: types.ArticleListItem) => {
  const articleImage = getImage(image);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      role="listitem"
      aria-label={title}
      component={motion.div}
      whileHover={{ scale: 1.1 }}
    >
      <Link to={`/blog/articles/${slug}`} underline="none" aria-label={title}>
        <Card sx={{ height: '100%', cursor: 'pointer' }}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" sx={{ bgcolor: 'red.500' }}>
                R
              </Avatar>
            }
            title="ทีมงาน CODE SASS"
            subheader={updatedAt}
          />
          {articleImage && (
            <GatsbyImage image={articleImage} title={title} alt={title} />
          )}
          <CardContent>
            <Typography gutterBottom variant="body1" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {excerpt}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default ArticleItem;
