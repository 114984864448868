import { Box, Typography, Stack } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  return (
    <Box display="flex" justifyContent="center" width="100%" bgcolor="#fbf9f9">
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        columnGap={8}
        maxWidth={1_100}
      >
        <Box maxWidth={350} my={2}>
          <StaticImage
            src="../../../assets/images/header-logo.png"
            alt="Logo"
          />
        </Box>
        <Stack alignItems="center" m={{ xs: 2, md: 0 }}>
          <Typography variant="h2" mb={2}>
            โค้ดศาสตร์
          </Typography>
          <Box sx={{ lineHeight: 2.5 }}>
            <Typography
              variant="h5"
              component="span"
              color="#fdcb6e"
              bgcolor="#353535"
              p={0.5}
              borderRadius={5}
            >
              ศาสตร์การเขียนโค้ดเริ่มต้นที่นี่{' '}
            </Typography>
            <Typography variant="h5" component="span" color="grey.700" ml={1}>
              เราจึงคัดสรรคอร์สที่เหมาะสมกับผู้เรียนทุกระดับ
              เพื่ออัพสกิลสู่การเป็นโปรแกรมเมอร์สกิลเทพ
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
