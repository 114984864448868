import { Box, Typography } from '@mui/material';

import { courses as coursesTypes } from '@codesass/types';

import CourseList from 'modules/courses/components/CourseList';
import ButtonLink from 'modules/ui/components/ButtonLink';

type CoursesProps = {
  courses: coursesTypes.CourseListItem[];
};

const Courses = ({ courses }: CoursesProps) => {
  return (
    <Box m={2} display="flex" justifyContent="center">
      <Box maxWidth={1_100} width="100%">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Typography variant="h4">คอร์สออนไลน์</Typography>
          <ButtonLink color="primary" variant="contained" to="/courses">
            ดูทั้งหมด
          </ButtonLink>
        </Box>
        <CourseList courses={courses} displayTitle={false}></CourseList>
      </Box>
    </Box>
  );
};

export default Courses;
